import {
  addCustomNetwork,
  constants,
  L1Network,
  L2Network
} from '@arbitrum/sdk'
import { networks as arbitrumSdkChains } from '@arbitrum/sdk/dist/lib/dataEntities/networks'

import { loadEnvironmentVariableWithFallback } from './index'
import { getBridgeUiConfigForChain } from './bridgeUiConfig'
import { orbitMainnets, orbitTestnets } from './orbitChainsList'

export enum ChainId {
  // L1
  Ethereum = 1,
  // L1 Testnets
  Goerli = 5,
  Local = 1337,
  Sepolia = 11155111,
  // L2
  ArbitrumOne = 42161,
  ArbitrumNova = 42170,
  // L2 Testnets
  ArbitrumGoerli = 421613,
  ArbitrumSepolia = 421614,
  ArbitrumLocal = 412346,
  Blast = 81457,
  BlastSepolia = 168587773,
  // Orbit
  StylusTestnet = 23011913,
  Yield3BlastSepolia = 62110344257,
  Yield3BlastMainnet = 25300
}

const YIELD3_BLAST_SEPOLIA = {
  chainID: ChainId.Yield3BlastSepolia,
  confirmPeriodBlocks: 150,
  ethBridge: {
    bridge: '0xB70FCA5e6727C7AB648E2Ec011bEFbB6e84992c4',
    inbox: '0xd2B354028758c91cd93650ED939699333C4C9016',
    outbox: '0x9d73dF2199DE18d0f1bdC692138B2B7509eAEBd0',
    rollup: '0xC3C48F54B1dD301565929FCe26fa20c3603EdCEA',
    sequencerInbox: '0xe6aE93467d212A711Da71531E54afA34a23d03Cc'
  },
  explorerUrl: 'https://yield3.tech',
  rpcUrl: loadEnvironmentVariableWithFallback({
    env: process.env.NEXT_PUBLIC_YIELD3_SEPOLIA_RPC_URL,
    fallback: 'https://rpc.yield3.tech'
  }),
  isArbitrum: true,
  isCustom: true,
  name: 'YIELD3 Blast Sepolia',
  slug: 'yield3-blast-sepolia',
  partnerChainID: ChainId.BlastSepolia,
  partnerChainIDs: [],
  retryableLifetimeSeconds: 604800,
  nitroGenesisBlock: 0,
  nitroGenesisL1Block: 0,
  depositTimeout: 900000,
  blockTime: constants.ARB_MINIMUM_BLOCK_TIME_IN_SECONDS,
  nativeToken: '0x4dB8cdA73b31A6ecFEA2CF90a5dEe99334011A91',
  tokenBridge: {
    l1CustomGateway: '0xC22429D3752A62682bee047F12cd5e0f129618F0',
    l1ERC20Gateway: '0xCC1F694C63761D0E8130E5241034F99E8FBd80C9',
    l1GatewayRouter: '0x968B1055e7d7Ba2b11EA06E61B304BB297452AFc',
    l1MultiCall: '0xCE236b245c0c2dc620002c1f77ef6ED6dF452355',
    l1ProxyAdmin: '0x04a41dA249c3Ab31e32aA0F1F03b8286a0ab34eD',
    l1Weth: '0x0000000000000000000000000000000000000000',
    l1WethGateway: '0x0000000000000000000000000000000000000000',
    l2CustomGateway: '0x65c64042CF72b077b81B57DA6c530f0b06571580',
    l2ERC20Gateway: '0x34C1A4cdaC838319cD93093F3DB1642134c635f1',
    l2GatewayRouter: '0xa260D3796Ab7b8f46F547A597A97871fD9d7d47C',
    l2Multicall: '0xF88C869FC28B07810997fC988Acf102e08607412',
    l2ProxyAdmin: '0x53eeD5eEAA0481c630db188fF71F3767F55A476D',
    l2Weth: '0x0000000000000000000000000000000000000000',
    l2WethGateway: '0x0000000000000000000000000000000000000000'
  }
}

const BLAST_SEPOLIA: L2Network = {
  chainID: ChainId.BlastSepolia,
  name: 'Blast Sepolia',
  explorerUrl: 'https://testnet.blastscan.io/',
  partnerChainID: 11155111,
  partnerChainIDs: [ChainId.Yield3BlastSepolia],
  blockTime: 10,
  isCustom: false,
  isArbitrum: true,
  confirmPeriodBlocks: 20,
  retryableLifetimeSeconds: 86400 * 7,
  nitroGenesisBlock: 0,
  nitroGenesisL1Block: 0,
  depositTimeout: 900000,
  ethBridge: {
    bridge: '0xcf3ccAE16e2e3B325fF2c2AfA1013cce11DDD54C',
    inbox: '0xb8F0c15fcdcbEc9c5e88B8ab9cBB63e324250eE9',
    outbox: '0x2BBA8d53204592Dd5756de81C3ad69CBCE92fF20',
    rollup: '0xA50b312ffD57Ea8A783AD5Fd7E7F7B328AcAfF2b',
    sequencerInbox: '0x559a68B6Bdf2Ba14C5247a361a6D0417A61C6d45'
  },
  tokenBridge: {
    l1CustomGateway: '0x8EfeE63849B2E7b8F5dc6E712D8880fb6B748fC3',
    l1ERC20Gateway: '0x9a86090213a660fC7433177b0ddEA5947DC645E0',
    l1GatewayRouter: '0x491E337f32Bc9B1Fb90DCcfE3dc41C38420BE80b',
    l1MultiCall: '0xCE236b245c0c2dc620002c1f77ef6ED6dF452355',
    l1ProxyAdmin: '0x0000000000000000000000000000000000000000',
    l1Weth: '0x0000000000000000000000000000000000000000',
    l1WethGateway: '0x0000000000000000000000000000000000000000',
    l2CustomGateway: '0xfa3fA4532e8c96b2adcb61cf36B1fb1366b7a03a',
    l2ERC20Gateway: '0x67030E1cdAef44E7B37818abC0621F73Ae2923ad',
    l2GatewayRouter: '0x03EB48De7a3E290e156b1B96947235ff413710e0',
    l2Multicall: '0x77709075DE29944F8d2651A6441Ae6B9cFc30B94',
    l2ProxyAdmin: '0x0000000000000000000000000000000000000000',
    l2Weth: '0x0000000000000000000000000000000000000000',
    l2WethGateway: '0x0000000000000000000000000000000000000000'
  }
}

const BLAST_MAINNET: L2Network = {
  chainID: ChainId.Blast,
  name: 'Blast Mainnet',
  explorerUrl: 'https://blastscan.io/',
  partnerChainID: 1,
  partnerChainIDs: [ChainId.Yield3BlastMainnet],
  blockTime: 10,
  isCustom: false,
  isArbitrum: true,
  confirmPeriodBlocks: 50400,
  retryableLifetimeSeconds: 604800,
  nitroGenesisBlock: 0,
  nitroGenesisL1Block: 0,
  depositTimeout: 900000,
  ethBridge: {
    bridge: '0x7476673E51A97FFF4a17E3A7B6e9F4c790305605',
    inbox: '0xa1C1B7aC54f088B13f96031A288a31c68589B588',
    outbox: '0xE6B329985aaA2819266A6A8b811B13b9B2E8C121',
    rollup: '0x2578b1e85e8979fe325cb13720117b2236b96f6B',
    sequencerInbox: '0x83Db827641d03B34d7f98f8d0b3a088E9F455Aa8'
  },
  tokenBridge: {
    l1CustomGateway: '0xe96461DC227d5bB9c6b580f6df37180e864390a3',
    l1ERC20Gateway: '0x533A69b184d5502dbc1E7d7089C91B5D092e0c8A',
    l1GatewayRouter: '0x9c50ea0F73cA013a52b6aE481A34cD6A83E75a0B',
    l1MultiCall: '0xbf29873A1895117cB2d565907ba1f85bEf285A7C',
    l1ProxyAdmin: '0x0000000000000000000000000000000000000000',
    l1Weth: '0x0000000000000000000000000000000000000000',
    l1WethGateway: '0x0000000000000000000000000000000000000000',
    l2CustomGateway: '0x5584b76032f008A3feDC39a1fd327Df3deacbf3E',
    l2ERC20Gateway: '0x5De8719535E8E5a729E3E0288eBd64039D5cA8A6',
    l2GatewayRouter: '0x0527107170ABC59996b7d859570b446763F882D9',
    l2Multicall: '0xb0bF5351421676f0912e1389EC875d39C9F3cB71',
    l2ProxyAdmin: '0x0000000000000000000000000000000000000000',
    l2Weth: '0x0000000000000000000000000000000000000000',
    l2WethGateway: '0x0000000000000000000000000000000000000000'
  }
}

const YIELD3_BLAST_MAINNET = {
  chainID: ChainId.Yield3BlastMainnet,
  confirmPeriodBlocks: 50400,
  ethBridge: {
    bridge: '0x9e4A324938a9a3EDFD3dB99E18b3da208152F4dC',
    inbox: '0x956c7A021c1585a2974d47389bB3d7429a23876C',
    outbox: '0xf55Bf0Fe0F7c3aeA9874334D2A3EdFB20AceF6Ac',
    rollup: '0x66d593F5324ea476353cD3c83648bd6bC03304cd',
    sequencerInbox: '0xF16801B0736aCC9FBA5d524d67Fe6021EF904268'
  },
  explorerUrl: 'https://y3.network',
  rpcUrl: loadEnvironmentVariableWithFallback({
    env: process.env.NEXT_PUBLIC_YIELD3_RPC_URL,
    fallback: 'https://rpc.y3.network'
  }),
  isArbitrum: true,
  isCustom: true,
  name: 'YIELD3',
  slug: 'yield3-blast-mainnet',
  partnerChainID: ChainId.Blast,
  partnerChainIDs: [],
  retryableLifetimeSeconds: 604800,
  nitroGenesisBlock: 0,
  nitroGenesisL1Block: 0,
  depositTimeout: 900000,
  blockTime: constants.ARB_MINIMUM_BLOCK_TIME_IN_SECONDS,
  nativeToken: '0x84e35036a7Ce45f53866570aE83a6BF1E8338B6E',
  tokenBridge: {
    l1CustomGateway: '0xb1183d270e9a97eF2b2E5bDa9391283d5934320B',
    l1ERC20Gateway: '0xE93F7BA0860aEfbEE79b20564430E7032394e0AA',
    l1GatewayRouter: '0xD0f42853B30077fC130A5260df9353755D4B912D',
    l1MultiCall: '0xbf29873A1895117cB2d565907ba1f85bEf285A7C',
    l1ProxyAdmin: '0x8464B86268CD77633E97CA6bD1d1ba4A528BE73a',
    l1Weth: '0x0000000000000000000000000000000000000000',
    l1WethGateway: '0x0000000000000000000000000000000000000000',
    l2CustomGateway: '0x442631f4D2197914741DDC7da412829C86f9cb51',
    l2ERC20Gateway: '0x85358972F6FA458892f483fBf8d91c98FcE78E4e',
    l2GatewayRouter: '0xCfCDfbA81e0B72393B2dB435fB5447441DA60E18',
    l2Multicall: '0x9A1e23E63D8e8978DC402e94A98CC52f9FD21070',
    l2ProxyAdmin: '0x4A26c0FC964094ea5D2F8cB47E7460062A24cBbB',
    l2Weth: '0x0000000000000000000000000000000000000000',
    l2WethGateway: '0x0000000000000000000000000000000000000000'
  }
}

// monkey patch
arbitrumSdkChains[BLAST_SEPOLIA.chainID] = BLAST_SEPOLIA
arbitrumSdkChains[YIELD3_BLAST_SEPOLIA.chainID] = YIELD3_BLAST_SEPOLIA
arbitrumSdkChains[BLAST_MAINNET.chainID] = BLAST_MAINNET
arbitrumSdkChains[YIELD3_BLAST_MAINNET.chainID] = YIELD3_BLAST_MAINNET

export const getChains = () => {
  const networkFilter = [
    ChainId.BlastSepolia,
    ChainId.Yield3BlastSepolia,
    ChainId.Blast,
    ChainId.Yield3BlastMainnet
  ]
  const chains = Object.values(arbitrumSdkChains)
  return chains.filter(chain => networkFilter.includes(chain.chainID))
}

export const customChainLocalStorageKey = 'arbitrum:custom:chains'

export const INFURA_KEY = process.env.NEXT_PUBLIC_INFURA_KEY

if (typeof INFURA_KEY === 'undefined') {
  throw new Error('Infura API key not provided')
}

const MAINNET_INFURA_RPC_URL = `https://mainnet.infura.io/v3/${INFURA_KEY}`
const GOERLI_INFURA_RPC_URL = `https://goerli.infura.io/v3/${INFURA_KEY}`
const SEPOLIA_INFURA_RPC_URL = `https://sepolia.infura.io/v3/${INFURA_KEY}`
const BLAST_MAINNET_RPC_URL = `https://rpc.blast.io/`
const BLAST_SEPOLIA_RPC_URL = `https://sepolia.blast.io/`
const YIELD3_BLAST_SEPOLIA_RPC_URL = `https://rpc.yield3.tech/`

export type ChainWithRpcUrl = L2Network & {
  rpcUrl: string
  slug?: string
}

function getParentChain(chain: L2Network): L1Network | L2Network {
  const parentChain = arbitrumSdkChains[chain.partnerChainID]

  if (typeof parentChain === 'undefined') {
    throw new Error(
      `[getParentChain] parent chain ${chain.partnerChainID} not found for ${chain.chainID}`
    )
  }

  return parentChain
}

export function getBaseChainIdByChainId({
  chainId
}: {
  chainId: number
}): number {
  const chain = arbitrumSdkChains[chainId]

  // the chain provided is an L1 chain, so we can return early
  if (!chain || isL1Chain(chain)) {
    return chainId
  }

  let currentParentChain = getParentChain(chain)
  // keep following the parent chains until we find the L1 chain
  while (!isL1Chain(currentParentChain)) {
    currentParentChain = getParentChain(currentParentChain)
  }

  return currentParentChain.chainID
}

export function getCustomChainsFromLocalStorage(): ChainWithRpcUrl[] {
  const customChainsFromLocalStorage = localStorage.getItem(
    customChainLocalStorageKey
  )

  if (!customChainsFromLocalStorage) {
    return []
  }

  return (JSON.parse(customChainsFromLocalStorage) as ChainWithRpcUrl[])
    .filter(
      // filter again in case local storage is compromised
      chain => !supportedCustomOrbitParentChains.includes(Number(chain.chainID))
    )
    .map(chain => {
      return {
        ...chain,
        // make sure chainID is numeric
        chainID: Number(chain.chainID)
      }
    })
}

export function getCustomChainFromLocalStorageById(chainId: ChainId) {
  const customChains = getCustomChainsFromLocalStorage()

  if (!customChains) {
    return undefined
  }

  return customChains.find(chain => chain.chainID === chainId)
}

export function saveCustomChainToLocalStorage(newCustomChain: ChainWithRpcUrl) {
  const customChains = getCustomChainsFromLocalStorage()

  if (
    customChains.findIndex(chain => chain.chainID === newCustomChain.chainID) >
    -1
  ) {
    // chain already exists
    return
  }

  const newCustomChains = [...getCustomChainsFromLocalStorage(), newCustomChain]
  localStorage.setItem(
    customChainLocalStorageKey,
    JSON.stringify(newCustomChains)
  )
}

export function removeCustomChainFromLocalStorage(chainId: number) {
  const newCustomChains = getCustomChainsFromLocalStorage().filter(
    chain => chain.chainID !== chainId
  )
  localStorage.setItem(
    customChainLocalStorageKey,
    JSON.stringify(newCustomChains)
  )
}

export const supportedCustomOrbitParentChains = [
  ChainId.Sepolia,
  ChainId.ArbitrumGoerli,
  ChainId.ArbitrumSepolia,
  ChainId.BlastSepolia,
  ChainId.Blast
]

export const rpcURLs: { [chainId: number]: string } = {
  // L1
  [ChainId.Ethereum]: loadEnvironmentVariableWithFallback({
    env: process.env.NEXT_PUBLIC_ETHEREUM_RPC_URL,
    fallback: MAINNET_INFURA_RPC_URL
  }),
  // L1 Testnets
  [ChainId.Goerli]: loadEnvironmentVariableWithFallback({
    env: process.env.NEXT_PUBLIC_GOERLI_RPC_URL,
    fallback: GOERLI_INFURA_RPC_URL
  }),
  [ChainId.Sepolia]: loadEnvironmentVariableWithFallback({
    env: process.env.NEXT_PUBLIC_SEPOLIA_RPC_URL,
    fallback: SEPOLIA_INFURA_RPC_URL
  }),
  // L2
  [ChainId.ArbitrumOne]: 'https://arb1.arbitrum.io/rpc',
  [ChainId.ArbitrumNova]: 'https://nova.arbitrum.io/rpc',
  [ChainId.Blast]: loadEnvironmentVariableWithFallback({
    env: process.env.NEXT_PUBLIC_BLAST_MAINNET_RPC_URL,
    fallback: BLAST_MAINNET_RPC_URL
  }),
  // L2 Testnets
  [ChainId.ArbitrumGoerli]: 'https://goerli-rollup.arbitrum.io/rpc',
  [ChainId.ArbitrumSepolia]: 'https://sepolia-rollup.arbitrum.io/rpc',
  [ChainId.BlastSepolia]: loadEnvironmentVariableWithFallback({
    env: process.env.NEXT_PUBLIC_BLAST_SEPOLIA_RPC_URL,
    fallback: BLAST_SEPOLIA_RPC_URL
  }),
  // Orbit Testnets
  [ChainId.StylusTestnet]: 'https://stylus-testnet.arbitrum.io/rpc',
  [ChainId.Yield3BlastSepolia]: loadEnvironmentVariableWithFallback({
    env: process.env.NEXT_PUBLIC_YIELD3_SEPOLIA_RPC_URL,
    fallback: YIELD3_BLAST_SEPOLIA_RPC_URL
  }),
  [ChainId.Yield3BlastMainnet]: loadEnvironmentVariableWithFallback({
    env: process.env.NEXT_PUBLIC_YIELD3_MAINNET_RPC_URL,
    fallback: 'https://rpc.y3.network'
  })
}

export const explorerUrls: { [chainId: number]: string } = {
  // L1
  [ChainId.Ethereum]: 'https://etherscan.io',
  // L1 Testnets
  [ChainId.Goerli]: 'https://goerli.etherscan.io',
  [ChainId.Sepolia]: 'https://sepolia.etherscan.io',
  // L2
  [ChainId.ArbitrumNova]: 'https://nova.arbiscan.io',
  [ChainId.ArbitrumOne]: 'https://arbiscan.io',
  [ChainId.Blast]: 'https://blastscan.io/',
  // L2 Testnets
  [ChainId.ArbitrumGoerli]: 'https://goerli.arbiscan.io',
  [ChainId.ArbitrumSepolia]: 'https://sepolia.arbiscan.io',
  [ChainId.BlastSepolia]: 'https://testnet.blastscan.io',
  // Orbit Testnets
  [ChainId.StylusTestnet]: 'https://stylus-testnet-explorer.arbitrum.io',
  [ChainId.Yield3BlastSepolia]: 'https://yield3.tech',
  [ChainId.Yield3BlastMainnet]: 'https://explorer.y3.network'
}

export const getExplorerUrl = (chainId: ChainId) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return explorerUrls[chainId] ?? explorerUrls[ChainId.Ethereum]! //defaults to etherscan, can never be null
}

export const getBlockTime = (chainId: ChainId) => {
  const network = arbitrumSdkChains[chainId]
  if (!network) {
    throw new Error(`Couldn't get block time. Unexpected chain ID: ${chainId}`)
  }
  return network.blockTime
}

export const getConfirmPeriodBlocks = (chainId: ChainId) => {
  const network = arbitrumSdkChains[chainId]
  if (!network || !isArbitrumChain(network)) {
    throw new Error(
      `Couldn't get confirm period blocks. Unexpected chain ID: ${chainId}`
    )
  }
  return network.confirmPeriodBlocks
}

export const l2ArbReverseGatewayAddresses: { [chainId: number]: string } = {
  [ChainId.ArbitrumOne]: '0xCaD7828a19b363A2B44717AFB1786B5196974D8E',
  [ChainId.ArbitrumNova]: '0xbf544970E6BD77b21C6492C281AB60d0770451F4',
  [ChainId.ArbitrumGoerli]: '0x584d4D9bED1bEb39f02bb51dE07F493D3A5CdaA0'
}

export const l2DaiGatewayAddresses: { [chainId: number]: string } = {
  [ChainId.ArbitrumOne]: '0x467194771dAe2967Aef3ECbEDD3Bf9a310C76C65',
  [ChainId.ArbitrumNova]: '0x10E6593CDda8c58a1d0f14C5164B376352a55f2F'
}

export const l2wstETHGatewayAddresses: { [chainId: number]: string } = {
  [ChainId.ArbitrumOne]: '0x07d4692291b9e30e326fd31706f686f83f331b82'
}

export const l2LptGatewayAddresses: { [chainId: number]: string } = {
  [ChainId.ArbitrumOne]: '0x6D2457a4ad276000A615295f7A80F79E48CcD318'
}

const defaultL1Network: L1Network = {
  blockTime: 10,
  chainID: 1337,
  explorerUrl: '',
  isCustom: true,
  name: 'Ethereum Local',
  partnerChainIDs: [412346],
  isArbitrum: false
}

const defaultL2Network: L2Network = {
  chainID: 412346,
  partnerChainIDs: [
    // Orbit chains will go here
  ],
  confirmPeriodBlocks: 20,
  ethBridge: {
    bridge: '0x2b360a9881f21c3d7aa0ea6ca0de2a3341d4ef3c',
    inbox: '0xff4a24b22f94979e9ba5f3eb35838aa814bad6f1',
    outbox: '0x49940929c7cA9b50Ff57a01d3a92817A414E6B9B',
    rollup: '0x65a59d67da8e710ef9a01eca37f83f84aedec416',
    sequencerInbox: '0xe7362d0787b51d8c72d504803e5b1d6dcda89540'
  },
  explorerUrl: '',
  isArbitrum: true,
  isCustom: true,
  name: 'Arbitrum Local',
  partnerChainID: 1337,
  retryableLifetimeSeconds: 604800,
  nitroGenesisBlock: 0,
  nitroGenesisL1Block: 0,
  depositTimeout: 900000,
  blockTime: constants.ARB_MINIMUM_BLOCK_TIME_IN_SECONDS,
  tokenBridge: {
    l1CustomGateway: '0x75E0E92A79880Bd81A69F72983D03c75e2B33dC8',
    l1ERC20Gateway: '0x4Af567288e68caD4aA93A272fe6139Ca53859C70',
    l1GatewayRouter: '0x85D9a8a4bd77b9b5559c1B7FCb8eC9635922Ed49',
    l1MultiCall: '0xA39FFA43ebA037D67a0f4fe91956038ABA0CA386',
    l1ProxyAdmin: '0x7E32b54800705876d3b5cFbc7d9c226a211F7C1a',
    l1Weth: '0xDB2D15a3EB70C347E0D2C2c7861cAFb946baAb48',
    l1WethGateway: '0x408Da76E87511429485C32E4Ad647DD14823Fdc4',
    l2CustomGateway: '0x525c2aBA45F66987217323E8a05EA400C65D06DC',
    l2ERC20Gateway: '0xe1080224B632A93951A7CFA33EeEa9Fd81558b5e',
    l2GatewayRouter: '0x1294b86822ff4976BfE136cB06CF43eC7FCF2574',
    l2Multicall: '0xDB2D15a3EB70C347E0D2C2c7861cAFb946baAb48',
    l2ProxyAdmin: '0xda52b25ddB0e3B9CC393b0690Ac62245Ac772527',
    l2Weth: '0x408Da76E87511429485C32E4Ad647DD14823Fdc4',
    l2WethGateway: '0x4A2bA922052bA54e29c5417bC979Daaf7D5Fe4f4'
  }
}

export type RegisterLocalNetworkParams = {
  l1Network: L1Network
  l2Network: L2Network
}

const registerLocalNetworkDefaultParams: RegisterLocalNetworkParams = {
  l1Network: defaultL1Network,
  l2Network: defaultL2Network
}

export const localL1NetworkRpcUrl = loadEnvironmentVariableWithFallback({
  env: process.env.NEXT_PUBLIC_LOCAL_ETHEREUM_RPC_URL,
  fallback: 'http://localhost:8545'
})
export const localL2NetworkRpcUrl = loadEnvironmentVariableWithFallback({
  env: process.env.NEXT_PUBLIC_LOCAL_ARBITRUM_RPC_URL,
  fallback: 'http://localhost:8547'
})

export function registerLocalNetwork(
  params: RegisterLocalNetworkParams = registerLocalNetworkDefaultParams
) {
  const { l1Network, l2Network } = params

  try {
    rpcURLs[l1Network.chainID] = localL1NetworkRpcUrl
    rpcURLs[l2Network.chainID] = localL2NetworkRpcUrl

    addCustomNetwork({ customL1Network: l1Network, customL2Network: l2Network })
  } catch (error: any) {
    console.error(`Failed to register local network: ${error.message}`)
  }
}

export function isNetwork(chainId: ChainId) {
  const customChains = getCustomChainsFromLocalStorage()
  const isMainnetOrbitChain = chainId in orbitMainnets
  const isTestnetOrbitChain = chainId in orbitTestnets

  const isEthereumMainnet = chainId === ChainId.Ethereum

  const isGoerli = chainId === ChainId.Goerli
  const isSepolia = chainId === ChainId.Sepolia
  const isLocal = chainId === ChainId.Local

  const isArbitrumOne = chainId === ChainId.ArbitrumOne
  const isArbitrumNova = chainId === ChainId.ArbitrumNova
  const isBlast = chainId === ChainId.Blast
  const isArbitrumGoerli = chainId === ChainId.ArbitrumGoerli
  const isArbitrumSepolia = chainId === ChainId.ArbitrumSepolia
  const isBlastSepolia = chainId === ChainId.BlastSepolia
  const isArbitrumLocal = chainId === ChainId.ArbitrumLocal

  const isStylusTestnet = chainId === ChainId.StylusTestnet
  const isYield3BlastSepolia = chainId === ChainId.Yield3BlastSepolia

  const isEthereumMainnetOrTestnet =
    isEthereumMainnet || isGoerli || isSepolia || isLocal

  const isArbitrum =
    isArbitrumOne ||
    isArbitrumNova ||
    isArbitrumGoerli ||
    isArbitrumLocal ||
    isArbitrumSepolia ||
    isBlastSepolia ||
    isBlast

  const customChainIds = customChains.map(chain => chain.chainID)
  const isCustomOrbitChain = customChainIds.includes(chainId)

  const isCoreChain = isEthereumMainnetOrTestnet || isArbitrum
  const isOrbitChain = !isCoreChain

  const isTestnet =
    isGoerli ||
    isLocal ||
    isArbitrumGoerli ||
    isArbitrumLocal ||
    isSepolia ||
    isArbitrumSepolia ||
    isBlastSepolia ||
    isCustomOrbitChain ||
    isStylusTestnet ||
    isYield3BlastSepolia ||
    isTestnetOrbitChain

  const isSupported =
    isArbitrumOne ||
    isArbitrumNova ||
    isEthereumMainnet ||
    isGoerli ||
    isArbitrumGoerli ||
    isSepolia ||
    isArbitrumSepolia ||
    isBlast ||
    isBlastSepolia ||
    isCustomOrbitChain ||
    isMainnetOrbitChain ||
    isTestnetOrbitChain

  return {
    // L1
    isEthereumMainnet,
    isEthereumMainnetOrTestnet,
    // L1 Testnets
    isGoerli,
    isSepolia,
    // L2
    isArbitrum,
    isArbitrumOne,
    isArbitrumNova,
    isBlast,
    // L2 Testnets
    isArbitrumGoerli,
    isArbitrumSepolia,
    isBlastSepolia,
    // Orbit chains
    isOrbitChain,
    isTestnet,
    // General
    isSupported,
    // Core Chain is a chain category for the UI
    isCoreChain
  }
}

export function getNetworkName(chainId: number) {
  return getBridgeUiConfigForChain(chainId).network.name
}

export function getSupportedChainIds({
  includeMainnets = true,
  includeTestnets = false
}: {
  includeMainnets?: boolean
  includeTestnets?: boolean
}): ChainId[] {
  return getChains()
    .map(chain => chain.chainID)
    .filter(chainId => {
      const { isTestnet } = isNetwork(chainId)
      if (includeMainnets && !includeTestnets) {
        return !isTestnet
      }
      if (!includeMainnets && includeTestnets) {
        return isTestnet
      }
      if (!includeMainnets && !includeTestnets) {
        return false
      }
      return true
    })
}

export function mapCustomChainToNetworkData(chain: ChainWithRpcUrl) {
  // custom chain details need to be added to various objects to make it work with the UI
  //
  // add RPC
  rpcURLs[chain.chainID] = chain.rpcUrl
  // explorer URL
  explorerUrls[chain.chainID] = chain.explorerUrl
}

function isL1Chain(chain: L1Network | L2Network): chain is L1Network {
  return !chain.isArbitrum
}

function isArbitrumChain(chain: L1Network | L2Network): chain is L2Network {
  return chain.isArbitrum
}

export function getDestinationChainIds(chainId: ChainId): ChainId[] {
  const chains = getChains()
  const arbitrumSdkChain = chains.find(chain => chain.chainID === chainId)

  if (!arbitrumSdkChain) {
    return []
  }

  const parentChainId = isArbitrumChain(arbitrumSdkChain)
    ? arbitrumSdkChain.partnerChainID
    : undefined

  const validDestinationChainIds =
    chains.find(chain => chain.chainID === chainId)?.partnerChainIDs || []

  if (parentChainId) {
    // always make parent chain the last element
    return [...validDestinationChainIds, parentChainId]
  }

  return validDestinationChainIds
}
